import React from 'react';
import { graphql } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import Img from "gatsby-image"

import styled from "styled-components"

import Layout from "../components/layout"
import SEO from '../components/seo';
import BackgroundImage from 'gatsby-background-image';
import ValuesList from '../components/values-list';



const Privacy = ( { data } ) => {

    const options = {
        renderNode: {
            "embedded-asset-block": (node) => {

                const url = node.data.target.fields.file["en-US"].url
                const alt = node.data.target.fields.title["en-US"]

                return <img style={{ width: "100%" }} alt={alt} src={url}/>
            }
        }
    }



    return ( 
        <Layout>
            <SEO 
            pageType="website"
            linkTitle={data.policy.linkTitle}
            pageDescription=''
            pageURL={`${data.webAddress.siteMetadata.url}privacy`}
            pageTitle={data.policy.pageTitle}
            linkImage={`https:${data.policy.linkPreviewImage.file.url}`}
            />
            <TopBanner>
                <PageTitle>{data.policy.bannerTitle}</PageTitle>
            </TopBanner>
            <LowerSection>
                <BioBody>
                    <Bio>{documentToReactComponents(data.policy.privacyPolicyText.json, options)}</Bio>

                </BioBody>

            </LowerSection>
           
        </Layout>
    );
}
 
export default Privacy;

export const pageQuery = graphql`
  query {
    policy:contentfulPrivacyPolicy(contentful_id: {eq: "7bIlZAN8mGzEq0Pw8RTo3I"}) {
        pageTitle
        linkTitle
        linkPreviewImage {
          file {
            url
          }
        }
        bannerTitle
        privacyPolicyText {
          json
        }
    }
    webAddress: site {
        siteMetadata {
          url
        }
    }
  }
`;

const TopBanner = styled.div`
  background-color: rgba(0, 66, 37, 1);
  position: relative;
  padding-top: 140px;
  padding-bottom: 140px;
  margin-top: 68px;
  display: flex !important;
  justify-content: center !important;
  align-items: center;
  @media (max-width: 450px) {
    padding-bottom: 100px;
    padding-top: 100px;
  }
`

const PageTitle = styled.h1`
  color: #FFFFFF;
  text-align: center;
  font-size: 38px;
  font-weight: 900;
  padding: 10px;
  display: inline;
  margin-bottom: 0;
  font-family: 'Raleway', sans-serif !important;
  @media (max-width: 450px) {
    font-size: 9vw;
  }
  
`

const LowerSection = styled.div`
  background-color: #FFFFFF;
  padding-top: 30px;
  padding-bottom: 30px;
  position: relative
`

const BioBody = styled.div`
  max-width: 1200px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 450px) {
      padding-left: 5px;
      padding-right: 5px;
    }
`

const FeaturedImage = styled(Img)`
  max-width: 850px;
  margin-left: auto;
  margin-right: auto;
  margin-top: -150px;
  margin-bottom: 60px;
  @media (max-width: 450px) {
        margin-bottom: 20px;
    }
`

const Bio = styled.div`
  font-size: 18px;
  font-weight: 400;
  color: black;
  line-height: 24px !important;
  padding-left: 50px;
  padding-right: 50px;
  text-align: none;
  font-family: 'Cormorant Garamond', serif !important;
  @media (max-width: 450px) {
    padding-left: 15px;
    padding-right: 15px;
    font-size: 4.3vw;
}
`